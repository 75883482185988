import React from "react"
import Layout from "../components/layout"
import '../components/index.scss'

const IndexPage = () => {
    return (
        <Layout page='Home'>
            <h1>Hello!</h1>
            <h3>Welcome to my site, I am a software developer currently living in Las Vegas.</h3>
        </Layout>
    )

}

export default IndexPage